<template>
  <div ref="focusElement" class="modern-color-theme text-left font-poppins flex flex-col gap-2">
    <VSLabel v-if="props.label" :for="id">{{ props.label }}</VSLabel>
    <button
      :id="id"
      ref="buttonElement"
      type="button"
      class="flex items-center w-full rounded-md px-3 py-2 ring-1 ring-inset text-sm leading-5 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600"
      :class="{
        'text-neutral-400 bg-neutral-150 ring-neutral-200': props.disabled,
        'text-neutral-950 bg-neutral-100 ring-neutral-300': !props.disabled
      }"
      :disabled="props.disabled"
      @click="menuOpen = menuOpen ? false : !props.readonly"
    >
      <slot />
    </button>
    <VTeleport v-if="menuOpen" to="body">
      <ul class="fixed z-[1001] w-full rounded-md bg-neutral-100 shadow-sm ring-1 ring-inset ring-neutral-250 flex flex-col p-1 overflow-y-auto max-h-72" :style="position">
        <li v-if="props.search || props.open" :class="{ 'mb-1': searchCollectionFlat.length > 0 }">
          <VInput v-model="searchModel" :placeholder="props.searchPlaceholder" focus @keydown.esc="menuOpen = false" @keydown.enter.prevent="add">
            <template #suffix>
              <button v-if="searchModel && props.open" :disabled="!canAdd" type="button" class="flex items-center justify-center" @click="add">
                <VIcon color="neutral" name="Solid/add-box" />
              </button>
              <VIcon v-else color="neutral" name="Solid/search" />
            </template>
          </VInput>
        </li>
        <VLazyDisplayable :collection="searchCollectionFlat" :size="25" tag="li">
          <template #default="{ item: option }">
            <li :style="{ paddingLeft: `${2 * option._nestingLevel}em` }">
              <template v-if="'value' in option">
                <button
                  class="px-4 pl-3 w-full text-sm leading-5 rounded-md flex gap-2 items-center whitespace-nowrap"
                  :title="option.label"
                  :disabled="option.disabled"
                  :class="{ 'bg-primary-150': props.isSelected(option), 'cursor-not-allowed text-neutral-350': option.disabled, 'cursor-pointer hover:bg-primary-150 text-neutral-850': !option.disabled }"
                  @click.prevent="select(option.value)"
                >
                  <VCheckbox v-if="props.multiple" :model-value="props.isSelected(option)" class="pointer-events-none" :tabindex="-1" />
                  <div class="py-2 truncate">{{ option.label ?? option.value }}</div>
                  <VIcon v-if="option.icon" class="ml-auto" dense :name="option.icon" />
                  <div v-else-if="option.badge" class="ml-auto rounded-full border border-neutral-500 max-w-32 text-neutral-500 flex gap-1.5 px-2.5 py-0.5 items-center justify-center" :title="option.badge.label">
                    <VIcon v-if="option.badge.icon" size="xs" :name="option.badge.icon" />
                    <div class="truncate text-xs leading-4 font-normal">
                      {{ option.badge.label }}
                    </div>
                  </div>
                </button>
              </template>
              <template v-else>
                <div class="px-4 pl-3 py-2 w-full text-sm leading-5 rounded-md flex gap-2 items-center whitespace-nowrap" :title="option.label">
                  <VIcon v-if="option.icon" dense :name="option.icon" />
                  {{ option.label }}
                </div>
              </template>
            </li>
          </template>
        </VLazyDisplayable>
      </ul>
    </VTeleport>
    <VSDescription v-if="props.description">{{ props.description }}</VSDescription>
    <VAlert v-if="validationResult" class="mt-2" dense :type="validationResult[0]" :message="validationResult[1]" />
  </div>
</template>
<script setup generic="T extends string | number" lang="ts">
import { useElementId } from '../../../utils/utils';
import VSLabel from './VSLabel.vue';
import VSDescription from './VSDescription.vue';
import VInput from '../VInput.vue'
import VCheckbox from '../VCheckbox.vue';
import VIcon from '../../labels/VIcon.vue';
import VAlert from '../../dialogs/VAlert.vue';
import VLazyDisplayable from '../../layouts/VLazyDisplayable.vue'
import { computed, ref, watch } from 'vue';
import type { ValidationResult } from '@component-utils/validations';
import { useSearch } from '@component-utils/search';
import { useDeepFocus } from '@component-utils/focus';
import { findSelectOption } from '../helpers/select';
import type { V } from '@component-utils/types';
import { useLocalize } from '@component-utils/localization';
import { useManualTracking } from '@component-utils/reactivity';
import VTeleport from '@component-library/utilities/VTeleport.vue';

defineOptions({
  name: 'VSSelectBase'
})

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    readonly?: boolean
    id?: string
    label?: string
    description?: string
    multiple?: boolean
    open?: boolean
    search?: boolean
    searchPlaceholder?: string
    // Diverging props
    isSelected: (option: V.Select.Item<T>) => boolean
    options: V.Select.Option<T>[]
    validationResult?: ValidationResult
  }>(),
  {
    id: undefined,
    search: false,
    searchPlaceholder: useLocalize('component-library.inputs')('default_search_placeholder'),
    readonly: false,
    disabled: false,
    open: false,
    label: undefined,
    placeholder: undefined,
    description: undefined,
    validationResult: undefined
  }
)

const emit = defineEmits<{
  select: [value: T],
  add: [value: T]
}>()

const menuOpen = ref(false)

const buttonElement = ref<HTMLButtonElement>()

const { focusElement, focusLost } = useDeepFocus()
focusLost(() => menuOpen.value = false)

const id = useElementId(props.id)

const validationResult = computed(() => props.validationResult)

const options = computed(() => props.options)

const { searchModel, searchValue, searchClear, searchCollectionFlat } = useSearch(options, ['label', 'value'], menuOpen, 'options')

const canAdd = computed(() => {
  const value = searchValue.value as T

  return props.open && value && !findSelectOption(value, 'label', props.options) && !findSelectOption(value, 'value', props.options)
})

const select = (value: T) => {
  emit('select', value)

  menuOpen.value = props.multiple
}

const add = () => {
  if (!canAdd.value) return

  emit('add', searchValue.value as T)

  menuOpen.value = props.multiple

  searchClear()
}

watch(menuOpen, (value) => {
  if (value) {
    const animationFrameCallback = () => {
      recomputePosition()

      if (menuOpen.value) {
        window.requestAnimationFrame(animationFrameCallback)
      }
    }

    window.requestAnimationFrame(animationFrameCallback)
  }
})

const { computedRef: position, recompute: recomputePosition } = useManualTracking(() => {
  const rect = buttonElement.value?.getBoundingClientRect()
  if (!rect) throw new Error('Element is not visible')

  return {
    top: `${rect.bottom + 4}px`,
    left: `${rect.left}px`,
    width: `${rect.width}px`
  }
})
</script>